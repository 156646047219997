import React from 'react';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import RichText from 'components/RichText';
import LearnPost from 'components/LearnPost';
import Container from 'react-bootstrap/Container';
import { extractSocialMeta } from 'utils/fieldExtractors';

const propTypes = {
  data: PropTypes.object,
  errors: PropTypes.array,
};

function DiscoveryVideo({ data, ...props }) {
  const doc = get(data, 'appPrismic.discoveryVideo');
  if (!doc) {
    return null;
  }

  const { title, meta, seoTitle, seoMeta } = doc;

  return (
    <Layout>
      <SEO
        title={seoTitle || get(title, '[0].text', '')}
        description={seoMeta}
        lang={meta}
        socialMeta={extractSocialMeta(doc)}
      />
      <Container>
        <LearnPost article={doc} {...props} />
      </Container>
    </Layout>
  );
}

export const appQuery = graphql`
  query DiscoveryVideoQuery($uid: String!, $lang: String!) {
    appPrismic {
      discoveryVideo: discovery_video(uid: $uid, lang: $lang) {
        ...DiscoveryVideoFragment
      }
    }
  }
`;

DiscoveryVideo.propTypes = propTypes;

export default DiscoveryVideo;
